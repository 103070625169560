import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setVin } from "../../redux/slices/vinReducer";
import { useDispatch, useSelector } from "react-redux";

import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import LiveDashboardBody from "../../Components/Body/LiveDashboardBody";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import navData from "../../service/navigation";
import loadash from "lodash";
import fetchApiData from "../../service/apiUtils";
import { toast ,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Import the default toast styles


function LiveDashboard() {
  
    // Check if the toast has already been shown in this session
    const [authenticated, setAuthenticated] = useState(null);
    const loggedInUser = localStorage.getItem("authenticated");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    window.onpopstate = () => {
      navigate("/app/livedashboard");
    };


    useEffect(() => {
    if (loggedInUser) {
    // Check if the toast has already been shown in this session
    if (!sessionStorage.getItem("toastShown")) {
      toast.success("Login Successful!");  // Show success toast
      sessionStorage.setItem("toastShown", "true");  // Mark that the toast has been shown
    }

    setAuthenticated(loggedInUser);

    const fetchData = async () => {
      const url = "vehicles";
      try {
        const data = await fetchApiData(url);
        dispatch(setVin(data.vins));
      } catch (error) {
        alert(error);
      }
    };

    fetchData();
  }
}, [loggedInUser]);  // Dependency array includes loggedInUser to re-run the effect when it changes



  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/livedashboard");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);

  let fields = loadash.cloneDeep(navData);
  fields[0].class = true;

  return (
    <>
      <ToastContainer />
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      <LiveDashboardBody/>
      <Footer />
    </>
  );
}

export default LiveDashboard;
